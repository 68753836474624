import { environment } from './../../../../environments/environment.prod';
import { MUser } from './../model/user';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable, map, catchError, throwError,BehaviorSubject} from 'rxjs';

@Injectable()
export class AuthService {
  isLogin: boolean = false;
  roleAs: unknown = [];
  private admin_module = new BehaviorSubject<string[]>([]);
  admin_module$ = this.admin_module.asObservable();

  setAdminModule(admin_module: string[]) {
    this.admin_module.next(admin_module);
  }

  constructor(private httpClient: HttpClient) { }
  login(payload: any): Observable<object> {
    const { username, userpassword } = payload
    return this.httpClient.post<MUser[]>(`${environment.DEV_SERVER_API_URL}auth/admin/email-login`,payload)
  }

  logout() {
    this.isLogin = false;
    this.roleAs = '';
    localStorage.setItem('STATE', 'false');
    localStorage.setItem('ROLE', '');
    return of({ success: this.isLogin, role: '' });
  }

  isLoggedIn() {
    const loggedIn = localStorage.getItem('STATE');
    if (loggedIn == 'true')
      this.isLogin = true;
    else
      this.isLogin = false;
    return this.isLogin;
  }

  getRole() {
    const roles: any = localStorage.getItem('ROLE');
    this.roleAs = JSON.parse(roles)
    return this.roleAs;
  }

  getToken() {
    const authToken = localStorage.getItem('AUTH-TOKEN')
    return authToken
  }

  sendMail(payload: any) {
    return this.httpClient.post(`${environment.DEV_SERVER_API_URL}mailer/password`,payload)
  }
}


