import { Pipe, PipeTransform } from '@angular/core';
import { ViewBookingService } from '../container/booking/service/view-booking.service';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
  currencyTransform: any
  constructor(private viewBookingService: ViewBookingService) { }
  /**
   * 
   * @param regionId 
   * @param amount 
   * @returns 
   * this function format the amount accordiong to the region
   */
  transform(regionCurrency: string, amount: any): string {
    /**
     * this if else condition for region currency buisness logic
     * has been commented out by Anirban Mukherjee on 28.09.23  
     */
    // if (regionCurrency !== 'AED') {
    //   this.currencyTransform = `${regionCurrency}${this.formatAmount(amount)}`;
    // } else {
    //   this.currencyTransform = `${regionCurrency} ${this.formatAmount(amount)}`;
    // }
    this.currencyTransform = `${regionCurrency} ${this.formatAmount(amount)}`;
    return this.currencyTransform;
  }

  formatAmount(amount: number) {
    const convertedNumber = Number(amount);
    return convertedNumber.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

}
