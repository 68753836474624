import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ViewBookingService {


  constructor(private httpclient: HttpClient) { }

  getAddressList(address: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("address", address);
    return this.httpclient.get<any>(`${environment.DEV_SERVER_API_URL}misc-address`)
  }
  getViewBookingData(bookingId: any) {
    return this.httpclient.get<any>(`${environment.DEV_SERVER_API_URL}booking/${bookingId}`)
  }
  getvehicleCategory() {
    return this.httpclient.get<any>(`${environment.DEV_SERVER_API_URL}vehicle-categories`)
  }

  deleteBooking(bookingId: any) {
    return this.httpclient.delete<any>(`${environment.DEV_SERVER_API_URL}booking/admin/${bookingId}`)
  }
  saveRideStatus(booking_id: any, payload: any) {
    return this.httpclient.put<any>(`${environment.DEV_SERVER_API_URL}booking/reset/${booking_id}`, payload)
  }
  upatePaymentHistory(payload: any) {
    return this.httpclient.put<any>(`${environment.DEV_SERVER_API_URL}booking/booking-tariff`, { bookingTariff: payload })
  }
  deletePaymentHistory(bookingId: any, bookingSerial: any) {
    return this.httpclient.delete<any>(`${environment.DEV_SERVER_API_URL}booking/booking-tariff?&bookingId=${bookingId}&bookingSerial=${bookingSerial}`)
  }
  getRegionDetails(regionId: any) {
    return this.httpclient
      .get(
        `${environment.DEV_SERVER_API_URL}regions?region_id=${regionId}`
      )
  }
}
