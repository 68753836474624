import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor{
    constructor(private auth : AuthService){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       if(this.auth.isLoggedIn()){
            const  modifiedReq = req.clone({
                setHeaders: {
                    'Authorization': `Bearer ${this.auth.getToken()}`,
                    'Access-Control-Allow-Origin': '*'
                }
            });
            return next.handle(modifiedReq)
       }     
       return next.handle(req)
    }
}