// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  development: true,
  DEV_SERVER_API_URL:"https://api-dev.roldrive.com/api/v1/",
  //DEV_SERVER_API_URL: 'http://localhost:5001/api/v1/',
GOOGLE_MAP_API_KEY: 'AIzaSyAUT_c0p7Rb2ZlSz9l7oXiyFaHg87fk7TY',
  PHONE_VALIDATION_API_URL:
    'https://phonevalidation.abstractapi.com/v1/?api_key=a61cdb599976437099f63129059ca252',
  COUNTRY_FLAG_URL:
    'https://purecatamphetamine.github.io/country-flag-icons/3x2',
  SOCKET_CONNECTION_URL: 'https://api-dev.roldrive.com/events',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
