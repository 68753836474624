import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularmaterialmoduleModule } from './angularmaterialmodule/angularmaterialmodule.module';
import { CoreModule } from './core/core.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DetailsFormComponent } from './container/adminprofile/pages';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/services/auth.interceptor';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DatePipe } from '@angular/common';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'environments/environment.prod';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ErrorInterceptorServiceInterceptor } from './error-interceptor-service.interceptor';
import { CustomCurrencyPipe } from './shared/custom-currency.pipe';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatSidenavModule,
    SocketIoModule.forRoot({
      url: `${environment.SOCKET_CONNECTION_URL}`,
      // socket server url;
    }),
    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      // This is a INTERCEPTORS for checking user logged in or not if logged out redirect to login
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorServiceInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DatePipe,CustomCurrencyPipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [DetailsFormComponent],
})
export class AppModule {}
